<template>
  <div id="forgot">
    <div v-if="!sent" class="not-sent-box">
      <p class="forgot-text">Enter your email below to reset your password</p>
      <text-input
        type="email"
        v-model="emailAddress"
        label="Email"
      ></text-input>

      <button
        type="submit"
        class="button button-primary send"
        :disabled="!isComplete"
        v-on:click="requestReset()"
      >
        Reset password
      </button>
      <div class="final-row">
        <div class="error-box" v-if="error && error.length">
          <img
            src="../assets/warning-triangle.svg"
            title="error"
            alt="error triangle"
          />
          <p id="error-message">{{ this.error }}</p>
        </div>
        <p id="back-to-login">
          <router-link to="/login">Back to Sign In</router-link>
        </p>
      </div>
    </div>
    <div v-if="sent" class="sent-box">
      <p class="sent-text">
        An email has been sent that contains additional information
      </p>
      <router-link to="/login">
        <button class="button button-primary login">Log In</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import TextInput from '../components/TextInput'

export default {
  name: 'Forgot',
  components: { TextInput },
  data() {
    return {
      emailAddress: '',
      error: '',
      sent: false
    }
  },
  created() {
    const isSignedIn = this.$session.isSignedIn()

    if (isSignedIn) {
      this.$router.replace('/admin/user')
    }
  },
  methods: {
    requestReset: async function () {
      this.error = ''
      this.$events.$emit('showLoading')
      try {
        await this.$session.requestPasswordReset(this.emailAddress)
        this.sent = true
        this.$events.$emit('hideLoading')
      } catch (err) {
        this.$events.$emit('hideLoading')
        this.error = err.message
        // this.$events.$emit('error', err)
      }
    }
  },
  computed: {
    isComplete() {
      return this.emailAddress
    }
  }
}
</script>

<style lang="scss">
#forgot {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 24px 24px 0 24px;

  .forgot-text,
  .sent-text {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.7);
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    color: $white;
    max-width: 460px;
    margin-top: 15px;
  }

  label {
    color: $white;
  }

  .text-input {
    width: 458px;
    margin-top: 25px;
  }

  input,
  textarea {
    color: $white;
    background-color: transparent;
  }

  input:focus {
    border-bottom: 1px solid $white;
  }

  ::placeholder {
    color: $white;
  }

  .final-row {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 20px;
    width: 100%;
    padding: 1em 0.5em;

    #back-to-login,
    #back-to-login a {
      grid-column: 2;
      justify-self: end;
      text-decoration: underline;
      text-shadow: 1px 1px 0 $black-trans;
      font-family: Poppins;
      font-size: 14px;
      color: $white;
    }

    .error-box {
      grid-column: 1;
      display: flex;
      justify-self: start;
      align-items: center;

      #error-message {
        text-align: center;
        color: $vermilion;
        font-style: italic;
        margin-left: 10px;
      }
    }
  }

  button {
    width: 458px;
    margin-top: 35px;
    font-weight: 600;
    font-size: 19px;
    align-self: flex-start;
  }

  button.send {
    margin-top: 35px;
  }

  button.login {
    width: 240px;
    margin: 32px 0;
  }

  .sent-box {
    text-align: center;
  }
}
</style>
